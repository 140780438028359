import axios from '@axios'

export default {
  namespaced: true,
  state: {},
  getters: {},
  mutations: {},
  actions: {
    async fetchAll(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        axios
          .get('/sales', { params: queryParams })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    fetchOne(ctx, { id }) {
      return new Promise((resolve, reject) => {
        axios
          .get(`/sales/${id}`)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    add(ctx, saleData) {
      return new Promise((resolve, reject) => {
        axios
          .post('/sales', saleData)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    edit(ctx, saleData) {
      return new Promise((resolve, reject) => {
        const url = `/sales/${saleData.id}`
        axios
          .put(url, saleData)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    del(ctx, id) {
      return new Promise((resolve, reject) => {
        const url = `/sales/${id}`
        axios
          .delete(url)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },

  },
}
